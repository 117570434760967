/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    em: "em",
    ol: "ol"
  }, _provideComponents(), props.components), {Warning, Boss, Skill, Trait, Phase, CMInformation, InformationBlock, Boon, IdealRotation, Condition, Item, Instability, Specialization, Effect} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Boss) _missingMdxReference("Boss", true);
  if (!CMInformation) _missingMdxReference("CMInformation", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!IdealRotation) _missingMdxReference("IdealRotation", true);
  if (!InformationBlock) _missingMdxReference("InformationBlock", true);
  if (!Instability) _missingMdxReference("Instability", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Phase) _missingMdxReference("Phase", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(Warning, null, React.createElement(_components.p, null, "This guide is currently a work in progress. The information shown here is correct and POVs for each boss are planned to be added at a later date!")), "\n", React.createElement(Boss, {
    name: "mama",
    foodId: "43360",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12491",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Hammer",
    weapon1MainSigil1: "Force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Axe",
    weapon2OffSigil: "Impact",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Frenzy",
    weapon2MainInfusion1Id: "37131",
    foodId: "43360",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12491",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46763",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "82876",
    weapon2OffId: "46759",
    weapon2OffSigilId: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pet: ", React.createElement(Skill, {
    id: "43636",
    disableText: true,
    id: "43636"
  }), " Rock Gazelle for ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " at every Knight."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    name: "Oppressive Superiority",
    id: "2143"
  }), "."), "\n", React.createElement(_components.li, null, "Play with ", React.createElement(Skill, {
    name: "Unleashed Overbearing Smash",
    id: "63197"
  }), " and ", React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "OneWolfPack",
    id: "45717"
  }), " in a boss phase where your group lacks damage."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " ", React.createElement(_components.strong, null, "once"), " at the ", React.createElement(_components.em, null, "Mistlock Singularity"), ", as it should be used in a boss phase where your group lacks damage.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Stone Spirit",
    id: "12495"
  }), " is a source of ", React.createElement(Boon, {
    name: "Aegis"
  }), " you can precast for your party."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), " (Torch 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Stone Spirit",
    id: "12495"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " (Warhorn 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " (Warhorn 5)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1 (100%-75%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Precast your ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " towards Knight 1.\nBe careful to not hit the invisible hitbox, otherwise you will get in combat early.\nSwap to your Hammer set and place down ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " right before MAMA becomes vulnerable.\nFollow up with high priority Hammer skills, swap weapons and finish the phase on Sword/Axe.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If you precast ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " at Knight 1, skip it in phase 1 and instead drop it while moving away a bit after the phase has ended. MAMA will trigger it when teleporting at the start of ", React.createElement(_components.em, null, "Nightmare Devastation"), " (bubble)."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " (Longbow 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shockwave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Serpents Strike",
    id: "12482"
  }), " (Sword 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pounce",
    id: "69203"
  }), " (Sword 2)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Knight 1"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Move to the Knight with ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " and immediately transition into ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), "."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 (75%-50%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Leap back to MAMA with ", React.createElement(Skill, {
    name: "Pounce",
    id: "69203"
  }), ", then use Sword/Axe skills.\nFill with auto attacks in case your weapon swap is not up yet.\nSwap to Hammer, use ", React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), ", and finish the phase with auto attacks."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pounce",
    id: "69203"
  }), " (Sword 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Serpents Strike",
    id: "12482"
  }), " (Sword 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Hammer Strike",
    id: "63118"
  }), " (Hammer 1)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Knight 2"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Move to the Knight with ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), ".\nHelp with ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " using ", React.createElement(Skill, {
    id: "41524",
    id: "41524"
  }), ", then kill the Knight with ", React.createElement(Skill, {
    name: "Unleashed Overbearing Smash",
    id: "63197"
  }), " and auto attacks if needed."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "41524",
    id: "41524"
  }), " (F1)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Overbearing Smash",
    id: "63197"
  }), " (Hammer 3)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3 (50%-25%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " to move to MAMA and trigger ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), ".\nBurst with ", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), " and ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " (if available) into high priority Hammer skills.\nSwap weapons and finish the phase on Sword/Axe."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " (Hammer 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Serpents Strike",
    id: "12482"
  }), " (Sword 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pounce",
    id: "69203"
  }), " (Sword 2)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Knight 3"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Move to the Knight with ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " and immediately transition into ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), "."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 4 (25%-0%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Open with Sword/Axe skills.\nSwap weapons to Hammer and finish the phase with all Hammer skills and auto attacks if neccessary.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Serpents Strike",
    id: "12482"
  }), " can be timed to evade attacks from MAMA or damage from the pulsing poison field."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Serpents Strike",
    id: "12482"
  }), " (Sword 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pounce",
    id: "69203"
  }), " (Sword 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Overbearing Smash",
    id: "63197"
  }), " (Hammer 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " (Hammer 5)"), "\n"))), "\n", React.createElement(Boss, {
    name: "siax",
    foodId: "43360",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12491",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Hammer",
    weapon1MainSigil1: "Force",
    weapon1MainSigil2: "Serpentslaying",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Axe",
    weapon2OffSigil: "Impact",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Axe",
    weapon2MainSigil1: "Serpentslaying",
    weapon2MainInfusion1Id: "37131",
    foodId: "43360",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12491",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46763",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24658",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "24658",
    weapon2OffId: "46759",
    weapon2OffSigilId: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pet: ", React.createElement(Skill, {
    id: "44617",
    disableText: true,
    id: "44617"
  }), " Red Moa for ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " and ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), "."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    name: "Oppressive Superiority",
    id: "2143"
  }), "."), "\n", React.createElement(_components.li, null, "Play with ", React.createElement(Skill, {
    name: "Overbearing Smash",
    id: "63075"
  }), " and ", React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), "."), "\n", React.createElement(_components.li, null, "Remember to use ", React.createElement(Item, {
    id: "24658",
    id: "24658"
  }), " for this encounter!"), "\n", React.createElement(_components.li, null, "Try to ensure ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), " and ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), " every phase."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " ", React.createElement(_components.strong, null, "once"), " at the ", React.createElement(_components.em, null, "Mistlock Singularity"), ", as the cooldown needs to be up for phase 2."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), " (Torch 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " (Warhorn 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " (Warhorn 5)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1 (100%-66%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Place ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " as soon as you get in combat and follow up with the next skill immediately.\nEnsure ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), " and ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), ", then burst with high priority Hammer skills.\nSwap weapons and finish the phase on Axe/Axe.\nMake sure to save ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " for phase 2.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Precasting ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " and ", React.createElement(Skill, {
    name: "Spike Trap",
    id: "12476"
  }), " allows for instant ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), " and ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), "."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " (Longbow 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Overbearing Smash",
    id: "63075"
  }), " (Hammer 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 1"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use available Axe skills, ", React.createElement(Skill, {
    name: "Frenzied Attack",
    id: "43548"
  }), " and auto attacks until your assigned Echo is dead.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "With ", React.createElement(Instability, {
    name: "Outflanked"
  }), " try to flank the Echo."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Frenzied Attack",
    id: "43548"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Ricochet",
    id: "12466"
  }), " (Axe 1)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 (66%-33%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "You want to use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " and ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " to make up for your lack of burst in this phase.\nUse all available skills on Axe, then swap weapons and finish the phase on Hammer."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 2"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "All of your attacks in this split are melee, so make sure to move towards your assigned Echo as soon as possible.\nUse ", React.createElement(Skill, {
    name: "Frenzied Attack",
    id: "43548"
  }), " for ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " and ", React.createElement(Skill, {
    name: "Overbearing Smash",
    id: "63075"
  }), " to refresh ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), ".")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "With ", React.createElement(Instability, {
    name: "Outflanked"
  }), " try to flank the Echo."), "\n", React.createElement(_components.li, null, "You can place your ", React.createElement(Item, {
    name: "White Mantle Portal Device",
    id: "78978"
  }), " at the boss. This allows you to use ", React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " to get to the Echo quickly. Open it while attacking the Echo and take it once it is dead."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Frenzied Attack",
    id: "43548"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Overbearing Smash",
    id: "63075"
  }), " (Hammer 3)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3 (33%-0%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Move back to Siax quickly using ", React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), ".\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), " and burst with ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " into high priority Hammer skills.\nSwap to Axe/Axe and use everything until Siax is dead."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " (Hammer 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n"))), "\n", React.createElement(Boss, {
    name: "ensolyss",
    foodId: "91805",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12491",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Longbow",
    weapon1MainSigil1: "Serpentslaying",
    weapon1MainSigil2: "Severance",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Axe",
    weapon2OffSigil: "Impact",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Serpentslaying",
    weapon2MainInfusion1Id: "37131",
    foodId: "91805",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12491",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46765",
    weapon1MainSigil1Id: "24658",
    weapon1MainSigil2Id: "84505",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "24658",
    weapon2OffId: "46759",
    weapon2OffSigilId: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pet: ", React.createElement(Skill, {
    id: "12756",
    disableText: true,
    id: "12756"
  }), " Pig for damage from ", React.createElement(Skill, {
    id: "41406",
    id: "41406"
  }), " and CC from ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), "."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    id: "2128",
    id: "2128"
  }), "."), "\n", React.createElement(_components.li, null, "Remember to use ", React.createElement(Item, {
    id: "24658",
    id: "24658"
  }), " and ", React.createElement(Item, {
    id: "24868",
    id: "24868"
  }), " for this encounter!"), "\n", React.createElement(_components.li, null, "One ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " should swap ", React.createElement(Skill, {
    id: "12491",
    id: "12491"
  }), " for ", React.createElement(Skill, {
    id: "45970",
    id: "45970"
  }), " to allow your your ", React.createElement(Specialization, {
    name: "Renegade"
  }), " to play more offensively while still maintaing ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " ", React.createElement(_components.strong, null, "twice"), " at the ", React.createElement(_components.em, null, "Mistlock Singularity"), ".")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "All ", React.createElement(Specialization, {
    name: "Soulbeast",
    text: "Soulbeasts"
  }), " can precast ", React.createElement(Skill, {
    name: "Moa Stance",
    id: "45970"
  }), " to assist with Boons in the first split."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), " (Torch 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Moa Stance",
    id: "45970"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " (Warhorn 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " (Warhorn 5)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1 (100%-66%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), ", then combo ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " into ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " to assist with breaking the defiance bar.\nSwap weapons and immediately start bursting into ", React.createElement(Effect, {
    name: "Exposed"
  }), ".\nStart with ", React.createElement(Skill, {
    name: "Serpents Strike",
    id: "12482"
  }), " and ", React.createElement(Skill, {
    name: "Pounce",
    id: "69203"
  }), " to make their second cast fit before ", React.createElement(_components.em, null, "Nightmare Devastation"), " (bubble).")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If you have a precasted ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), ", delay the one casted in fight until after the bubble spawns.\nUsually this is right after your ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " ends."), "\n", React.createElement(_components.li, null, "When playing with ", React.createElement(Skill, {
    name: "Moa Stance",
    id: "45970"
  }), " use it near the end of the phase."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " (Longbow 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " (Longbow 4)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Serpents Strike",
    id: "12482"
  }), " (Sword 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pounce",
    id: "69203"
  }), " (Sword 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "41406",
    id: "41406"
  }), " (F1)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Slash",
    id: "12471"
  }), " (Sword 1)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pounce",
    id: "69203"
  }), " (Sword 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Serpents Strike",
    id: "12482"
  }), " (Sword 3)"), "\n", React.createElement(_components.li, null, "2x ", React.createElement(Skill, {
    name: "Slash",
    id: "12471"
  }), " ", React.createElement(Skill, {
    name: "Crippling Thrust",
    id: "12472"
  }), " ", React.createElement(Skill, {
    name: "Precision Swipe",
    id: "12473"
  }), " (Sword 1 chain)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "41406",
    id: "41406"
  }), " (F1)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pounce",
    id: "69203"
  }), " (Sword 2)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 1 & 2"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "We Heal as One",
    id: "31914"
  }), " shortly after the phase ends to extend your Boons.\nWhile capping your assigned Altar, avoid as much damage as possible to ensure ", React.createElement(Trait, {
    name: "Loud Whistle",
    id: "974"
  }), " in the next phase.\nCast ", React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " when it comes off cooldown to gain ", React.createElement(Boon, {
    name: "Quickness"
  }), " and extend your Boons via ", React.createElement(Trait, {
    name: "Essence of Speed",
    id: "2085"
  }), ".\nUse ", React.createElement(Skill, {
    name: "We Heal As One",
    id: "31914"
  }), " a second time near the end of the split phase.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " with ", React.createElement(Skill, {
    name: "Moa Stance",
    id: "45970"
  }), " and another ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " should take the northeast and northwest Altars next to the ", React.createElement(Specialization, {
    name: "Renegade"
  }), ". Group together in the north Altar and have the ", React.createElement(Specialization, {
    name: "Renegade"
  }), " share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " under ", React.createElement(Skill, {
    name: "Moa Stance",
    id: "45970"
  }), ". This ensures higher ", React.createElement(Boon, {
    name: "Alacrity"
  }), " uptime during the split for the two ", React.createElement(Specialization, {
    name: "Soulbeast",
    text: "Soulbeasts"
  }), " and makes their ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " cooldown recharge faster. The ", React.createElement(Specialization, {
    name: "Soulbeast",
    text: "Soulbeasts"
  }), " move into their own Altar right after the first capture tick happens."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "We Heal As One",
    id: "31914"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "We Heal As One",
    id: "31914"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 & 3 (66%-33% & 33%-0%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " before Ensolyss is vulnerable, then help stack ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " with ", React.createElement(Skill, {
    id: "41524",
    id: "41524"
  }), ".\nFill with ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " and ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " until the defiance bar appears.\nAs in phase 1, use ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " and ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " into the defiance bar.\nDuring ", React.createElement(Effect, {
    name: "Exposed"
  }), " burst with the same rotation as in phase 1.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In fast groups use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " immediately after ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " to get the cooldown back earlier in phase 3."), "\n", React.createElement(_components.li, null, "When playing with ", React.createElement(Skill, {
    name: "Moa Stance",
    id: "45970"
  }), " use it right as everyone regroups in the middle and again when it comes off cooldown near the end of the phase."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " (Longbow 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "41406",
    id: "41406"
  }), " (F1)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), " (F2)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " (Longbow 4)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Serpents Strike",
    id: "12482"
  }), " (Sword 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pounce",
    id: "69203"
  }), " (Sword 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Slash",
    id: "12471"
  }), " ", React.createElement(Skill, {
    name: "Crippling Thrust",
    id: "12472"
  }), " ", React.createElement(Skill, {
    name: "Precision Swipe",
    id: "12473"
  }), " (Sword 1 chain)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pounce",
    id: "69203"
  }), " (Sword 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Serpents Strike",
    id: "12482"
  }), " (Sword 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "41406",
    id: "41406"
  }), " (F1)"), "\n", React.createElement(_components.li, null, "2x ", React.createElement(Skill, {
    name: "Slash",
    id: "12471"
  }), " ", React.createElement(Skill, {
    name: "Crippling Thrust",
    id: "12472"
  }), " ", React.createElement(Skill, {
    name: "Precision Swipe",
    id: "12473"
  }), " (Sword 1 chain)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pounce",
    id: "69203"
  }), " (Sword 2)"), "\n"))), "\n", React.createElement(Boss, {
    name: "skorvald",
    foodId: "43360",
    utilityId: "9443",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12491",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Hammer",
    weapon1MainSigil1: "Impact",
    weapon1MainSigil2: "Elemental Slaying",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Axe",
    weapon2OffSigil: "Elemental Slaying",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Axe",
    weapon2MainSigil1: "Impact",
    weapon2MainInfusion1Id: "37131",
    foodId: "43360",
    utilityId: "9443",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12491",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46763",
    weapon1MainSigil1Id: "24868",
    weapon1MainSigil2Id: "24661",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "24868",
    weapon2OffId: "46759",
    weapon2OffSigilId: "24661"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pet: ", React.createElement(Skill, {
    id: "12756",
    disableText: true,
    id: "12756"
  }), " Pig for damage from ", React.createElement(Skill, {
    id: "41406",
    id: "41406"
  }), " and CC from ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), "."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    name: "Oppressive Superiority",
    id: "2143"
  }), "."), "\n", React.createElement(_components.li, null, "Play with ", React.createElement(Skill, {
    name: "Unleashed Overbearing Smash",
    id: "63197"
  }), " and ", React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Item, {
    id: "24661",
    id: "24661"
  }), " to speed up killing the Anomalies."), "\n", React.createElement(_components.li, null, "With good instabilities use ", React.createElement(Item, {
    id: "73191",
    id: "73191"
  }), " as Utility food."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " ", React.createElement(_components.strong, null, "twice"), " at the ", React.createElement(_components.em, null, "Mistlock Singularity"), "."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), " (Torch 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " (Warhorn 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " (Warhorn 5)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1 (100%-66%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " and ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), ".\nStart casting ", React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " as soon as you get in combat (without having a target).\nFollow up with ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), " to help break Skorvald's defiance bar.\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), " as soon as possible.\nUse ", React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), ", swap weapons and finish the phase with Axe/Axe skills and ", React.createElement(Skill, {
    id: "41406",
    id: "41406"
  }), "."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " (Longbow 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "41406",
    id: "41406"
  }), " (F1)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 1 & 2"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Contribute as much damage to the Anomalies as possible.\nIf possible, try to use two skills at each Anomaly.\nUse ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), " at the last island to refresh ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), " and ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), " for the upcoming boss phase.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You can use ", React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " to move through updrafts more quickly."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Item, {
    name: "Elemental Slaying",
    id: "24661"
  }), " to speed up killing the Anomalies."), "\n", React.createElement(_components.li, null, "With ", React.createElement(Instability, {
    name: "Outflanked"
  }), " try to flank the Anomalies."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 1")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 2")), React.createElement(_components.ol, {
    start: "2"
  }, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "41406",
    id: "41406"
  }), " (F1)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 3")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Hammer Strike",
    id: "63118"
  }), " (Hammer 1)"), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Island 4")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Overbearing Smash",
    id: "63197"
  }), " (Hammer 3)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 & 3 (66%-33% & 33%-0%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), " Skorvald right after taking the portal back to the boss.\nPlace your ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " and repeat the burst from phase 1 except for ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), "."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " (in phase 3)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "41406",
    id: "41406"
  }), " (F1)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n"))), "\n", React.createElement(Boss, {
    name: "artsariiv",
    video: "8bQUWBhMaHo",
    videoCreator: "Jetrell",
    foodId: "91805",
    utilityId: "9443",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12491",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Longbow",
    weapon1MainSigil1: "Hydromancy",
    weapon1MainSigil2: "Air",
    weapon1MainInfusion1Id: "37131",
    weapon2MainType: "Axe",
    weapon2MainAffix: "Berserker",
    weapon2MainSigil1: "Force",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Axe",
    weapon2OffSigil: "Severance",
    weapon2OffInfusionId: "37131",
    video: "8bQUWBhMaHo",
    videoCreator: "Jetrell",
    foodId: "91805",
    utilityId: "9443",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12491",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon1MainId: "46765",
    weapon1MainSigil1Id: "24597",
    weapon1MainSigil2Id: "24554",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "24615",
    weapon2OffId: "46759",
    weapon2OffSigilId: "84505"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pet: ", React.createElement(Skill, {
    id: "43636",
    disableText: true,
    id: "43636"
  }), " Rock Gazelle for CC phases."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    name: "Oppressive Superiority",
    id: "2143"
  }), " and ", React.createElement(Trait, {
    name: "Wilting Strike",
    id: "975"
  }), " instead of ", React.createElement(Trait, {
    name: "Natural Healing",
    id: "970"
  }), " for the first CC."), "\n", React.createElement(_components.li, null, "With good instabilities use ", React.createElement(Item, {
    id: "73191",
    id: "73191"
  }), " as Utility food."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " ", React.createElement(_components.strong, null, "once"), " at the ", React.createElement(_components.em, null, "Mistlock Singularity"), ", as the cooldown needs to be up for phase 3.\nIn slow kills you can use it twice instead.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), " and ", React.createElement(Skill, {
    name: "Spiritual Reprieve",
    id: "44626"
  }), " are sources of ", React.createElement(Boon, {
    name: "Resistance"
  }), " to precast for your party. It helps against the ", React.createElement(Condition, {
    name: "Weakness"
  }), " in phase 1."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), " (Torch 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " (Warhorn 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " (Warhorn 5)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1 (100%-66%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " and swap to Axe/Axe before getting in combat.\nMove through Artsariiv to safety behind her while using ", React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " and ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), ".\nFace away from the lasers during ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), ".\nUse Axe skills as well as ", React.createElement(Skill, {
    id: "41524",
    id: "41524"
  }), " before swapping to Longbow.\nTry to be within 240 units of Artsariiv for ", React.createElement(Item, {
    name: "Hydromancy",
    id: "24597"
  }), ".\nJump over the shockwave while casting ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), ".")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In fast groups use ", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), " as soon as you take the portal to the boss and start ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " when the marble hits to avoid cooldown issues."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " (Longbow 5)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "41524",
    id: "41524"
  }), " (F1)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Leave Beastmode",
    id: "43014"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), " (Longbow 2)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 1"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Command your unmerged pet to use ", React.createElement(Skill, {
    id: "43636",
    id: "43636"
  }), " on your assigned corner.\nThis will also apply ", React.createElement(Condition, {
    name: "Weakness"
  }), " due to ", React.createElement(Trait, {
    name: "Wilting Strike",
    id: "975"
  }), ".\nUse ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " while moving towards the clone.\nThen enter ", React.createElement(Skill, {
    name: "Beastmode",
    id: "42944"
  }), " again and finish the CC with ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), ".\nUse ", React.createElement(_components.em, null, "Nova Launch"), " to get back to the center.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Precasting ", React.createElement(Skill, {
    name: "Spike Trap",
    id: "12476"
  }), " with ", React.createElement(Trait, {
    name: "Trappers Expertise",
    id: "1075"
  }), " in your corner allows you to stay merged and go faster."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "43636",
    id: "43636"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " (Longbow 4)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Beastmode",
    id: "42944"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Nova Launch")), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 and 3 (66%-33% & 33%-0%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Start ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " when Artsariiv appears again in the middle.\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), " as soon as possible.\nSwap to Axe/Axe and burst.\nTry to time and position ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " in a way that you proc ", React.createElement(Item, {
    name: "Severance",
    id: "84505"
  }), ".")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Be careful to place your ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " close enough and not too early, or it will not trigger."), "\n", React.createElement(_components.li, null, "Swapping to Axe/Axe right after Artsariiv becomes vulnerable allows you to greed one hit of ", React.createElement(Item, {
    name: "Air",
    id: "24554"
  }), ". ", React.createElement(_components.strong, null, "Do not"), " delay the rest of your rotation for this!"), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " (Longbow 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " (in phase 3)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "41524",
    id: "41524"
  }), " (F1)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Split 2"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " on the clone at the side.\nSwap weapons as soon as possible.\nThen ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " to the corner while standing close to or inside the clone in order to hit both clones.\nFinally, finish the CC phase with ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " and ", React.createElement(_components.em, null, "Nova Launch"), " back to the center.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In a fast kill one ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " has to \"control\" the CC phase by delaying their ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), ". This is done to ensure cooldowns will be ready in the next phase. Usually the CC should be completed as or right after the marble hits the ground."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " (Longbow 4)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Nova Launch")), "\n"))), "\n", React.createElement(Boss, {
    name: "arkk",
    foodId: "43360",
    utilityId: "50082",
    healId: "12483",
    utility1Id: "12633",
    utility2Id: "12491",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Hammer",
    weapon1MainSigil1: "Force",
    weapon1MainSigil2: "Frenzy",
    weapon1MainInfusion1Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Axe",
    weapon2OffSigil: "Impact",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Axe",
    weapon2MainSigil1: "Force",
    weapon2MainInfusion1Id: "37131",
    foodId: "43360",
    utilityId: "50082",
    healId: "12483",
    utility1Id: "12633",
    utility2Id: "12491",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46763",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "82876",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "24615",
    weapon2OffId: "46759",
    weapon2OffSigilId: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pet: ", React.createElement(Skill, {
    id: "44617",
    disableText: true,
    id: "44617"
  }), " Red Moa for ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " and ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), "."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    name: "Oppressive Superiority",
    id: "2143"
  }), "."), "\n", React.createElement(_components.li, null, "Play with ", React.createElement(Skill, {
    name: "Overbearing Smash",
    id: "63075"
  }), " and ", React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), ". When precasting ", React.createElement(Skill, {
    name: "Spike Trap",
    id: "12476"
  }), " use ", React.createElement(Skill, {
    name: "Unleashed Overbearing Smash",
    id: "63197"
  }), " instead."), "\n", React.createElement(_components.li, null, "Swap to ", React.createElement(Item, {
    id: "50082",
    id: "50082"
  }), "!"), "\n", React.createElement(_components.li, null, "Try to ensure ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), " and ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), " every phase."), "\n", React.createElement(_components.li, null, "Stack in ", React.createElement(_components.em, null, "Cosmic Meteor"), " (green) whenever possible and use ", React.createElement(Skill, {
    name: "Harmonic Cry",
    id: "44617"
  }), " after to help with healing."), "\n", React.createElement(_components.li, null, "Wherever possible you should try to hit multiple targets with your ", React.createElement(Skill, {
    name: "Pathofscars",
    id: "12638"
  }), ". This can mean hitting an anomaly to help your ", React.createElement(Specialization, {
    name: "renegade"
  }), " kill it. Pulling the adds is also great since they can hurt your ", React.createElement(Trait, {
    name: "Loud Whistle",
    id: "974"
  }), " uptime."), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " ", React.createElement(_components.strong, null, "once"), " at the ", React.createElement(_components.em, null, "Mistlock Singularity"), ", as the cooldown needs to be up for phase 3."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), " (Torch 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " (Warhorn 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " (Warhorn 5)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1 (100%-80%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " as soon as you enter combat and follow up with the next skill immediately.\nOpen with high priority Hammer skills, then swap weapons and continue with Axe skills.\nUse ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " and ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " while facing away from the eye.\nAt the end of the phase ", React.createElement(Skill, {
    name: "Leave Beastmode",
    id: "43014"
  }), " to not unmerge in case the bomb goes off.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Precasting ", React.createElement(Skill, {
    name: "Spike Trap",
    id: "12476"
  }), " and ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " ensures instant ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), " and ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), ". This allows more comfortably fitting skills before the eye mechanic."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frenzied Attack",
    id: "43548"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Leave Beastmode",
    id: "43014"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Bloom Phases"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Position yourself far from your assigned Bloom and close to the lightning.\nUse ", React.createElement(Skill, {
    name: "Ricochet",
    id: "12466"
  }), " to push the Bloom in two hits."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Ricochet",
    id: "12466"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 (80%-70%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " for ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), " while running towards Arkk.\nFollow up with ", React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " to proc ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), ".\nUse available Axe skills, then swap weapons and finish the phase on Hammer.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If your ", React.createElement(_components.em, null, "Nova Launch"), " is up, you can use it to get back to Arkk quickly after pushing your Bloom."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Beastmode",
    id: "42944"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Archdiviner"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Immediately cast ", React.createElement(Skill, {
    name: "Frenzied Attack",
    id: "43548"
  }), " for ", React.createElement(Condition, {
    name: "Vulnerability"
  }), ".\nFill with skills until the Archdiviner will die soon.\nThen use ", React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " and during it press ", React.createElement(_components.em, null, "Hypernova Launch"), ".\nThis will grant you ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), " as well as ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), " for the next phase.")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "With ", React.createElement(Instability, {
    name: "Outflanked"
  }), " make sure to flank the Archdiviner and use your ", React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " into ", React.createElement(_components.em, null, "Hypernova Launch"), " combo more quickly to still get the hit."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Frenzied Attack",
    id: "43548"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Overbearing Smash",
    id: "63197"
  }), " (Hammer 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Hammer Strike",
    id: "63118"
  }), " ", React.createElement(Skill, {
    name: "Hammer Slam",
    id: "63222"
  }), " ", React.createElement(Skill, {
    name: "Heavy Smash",
    id: "63337"
  }), " (Hammer 1)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " (Hammer 5)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Hypernova Launch")), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3 (70%-50%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Cast both ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " and ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " right before Arkk teleports back.\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), " as soon as possible.\nUse Hammer skills, swap to Axe and position yourself where the last ", React.createElement(_components.em, null, "Horizon Strike"), " (pizza) slice will appear.\nPrioritize ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " and ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " to refresh ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), " and ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), ".\nStep out of the ", React.createElement(_components.em, null, "Horizon Strike"), " slice counter-clockwise as ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " ends."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 4 (50%-40%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " for ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), " while running towards Arkk.\nFollow up with ", React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " to proc ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), ".\nUse available Axe skills, then swap weapons and finish the phase on Hammer."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Gladiator"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Place ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " before the Gladiator spawns.\nImmediately ", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), " and cast ", React.createElement(Skill, {
    name: "Frenzied Attack",
    id: "43548"
  }), " for ", React.createElement(Condition, {
    name: "Vulnerability"
  }), ".\nUse ", React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " and during it press ", React.createElement(_components.em, null, "Hypernova Launch"), " for ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), " and ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), ".")), React.createElement(InformationBlock, {
    title: "Tips"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "With ", React.createElement(Instability, {
    name: "Outflanked"
  }), " make sure to flank the Gladiator and use your ", React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " into ", React.createElement(_components.em, null, "Hypernova Launch"), " combo more quickly to still get the hit. Due to the increased damage from flanking you can ", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), " Arkk instead after he ports out before the Gladiator spawns."), "\n"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Frenzied Attack",
    id: "43548"
  }), " (F2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Overbearing Smash",
    id: "63197"
  }), " (Hammer 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " (Hammer 5)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Hypernova Launch")), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 5 (40%-30%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Use high priority Hammer skills, then swap and use ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " to refresh ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), ".\nInterrupt your ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " early to still fit the rest of your Axe skills into the phase."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, "Short ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 6 (30%-0%)"
  }, React.createElement(InformationBlock, null, React.createElement(_components.p, null, "Open with ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " for ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), " while running towards Arkk and use ", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), ".\nFollow up with ", React.createElement(Skill, {
    name: "Frenzied Attack",
    id: "43548"
  }), " for ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " and ", React.createElement(Item, {
    name: "Relic of Fireworks",
    id: "100947"
  }), ".\nPlace ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " and use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), ".\nCast all Axe skills, then swap weapons and use Hammer skills until Arkk is dead."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " (Axe 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Frenzied Attack",
    id: "43548"
  }), " (F2)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Winters Bite",
    id: "12490"
  }), " (Axe 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " (F3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " (Axe 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Splitblade",
    id: "12480"
  }), " (Axe 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Weapon Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Savage Shock Wave",
    id: "63131"
  }), " (Hammer 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Wild Swing",
    id: "63335"
  }), " (Hammer 2)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Overbearing Smash",
    id: "63197"
  }), " (Hammer 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unleashed Thump",
    id: "63208"
  }), " (Hammer 5)"), "\n"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
